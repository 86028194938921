<template>
    <div class="container" aria-busy="true">
        <small>Loading excitement</small>
    </div>
</template>

<style scoped>
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }
</style>