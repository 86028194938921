import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashBoard from '../views/DashBoard.vue'
import { auth, db } from '@/utils'
import { doc, getDoc } from 'firebase/firestore'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    // { path: "/sponsor", component: () => import("../components/SponsorSection.vue") },
    { path: "/dashboard", component: DashBoard, meta: { requiresAuth: true } },
    { path: "/rulebook/:to", component: () => import("../components/RuleBook.vue"), meta: { requiresAuth: true } },
    { path: "/register", component: () => import("@/components/RegisterForm.vue"), meta: { requiresAuth: true } },
    { path: "/login", name: 'login', component: () => import("@/components/SignUp.vue") },
    { path: "/timeline", component: () => import("@/views/TimeLine.vue") },
    {
      path: "/admin",
      meta: { requiresAuth: true, requiresAdmin: true },
      children: [
        {
          path: '',
          component: () => import("@/views/admin/HomeView.vue")
        },
        {
          path: 'requests',
          component: () => import("@/views/admin/ParticipationReq.vue")
        }
      ]
    },
    {
      path: "/401",
      component: () => import("@/views/UnAuth.vue")
    }
  ]
})

let isAuthenticated = false
let isAdmin = false

router.beforeEach((to, from, next) => {

  auth.onAuthStateChanged((user) => {
    isAuthenticated = user ? true : false

    if (user) {
      getDoc(doc(db, 'users', user.email)).then((doc) => {
        if (!doc.exists()) return
        isAdmin = doc.data().admin ? doc.data().admin : false
      })
    }
  })
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' })
  } else if (to.matched.some(record => record.meta.requiresAdmin) && !isAdmin) {
    next({ name: '401' })
  } else {
    next()
  }

})

export default router
